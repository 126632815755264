.nav {
  padding: 20px;
  padding-top: 0px;
  height: 7em;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 100;
  position: absolute;
  /* background-color: beige; */
  overflow: hidden;
  box-sizing: border-box;
}



.contact-button {
  color: black;
}

.nav-link {
  margin: 10px;

  padding: 5px 10px 5px 10px;
  width: 5rem;
  color: rgb(115, 88, 34);
  font-family: "Raleway", sans-serif;

}

.nav-link-contact {
  margin: 10px;
  padding-top: 0em;

  width: 16rem;
  color: rgb(115, 88, 34);
  font-family: "Raleway", sans-serif;
  z-index: 99 !important;
  position: relative;
  float:right;
}

.nav-link-about {
  margin: 10px 10px 0px 10px;
  outline-color: rgb(04,54,54);
  outline-style: solid;
  outline-width: 2px;
  padding: 5px 10px 5px 10px;
  width: 5rem;
  color: rgb(115, 88, 34);
  font-family: "Raleway", sans-serif;
  border-radius: 300px;
  z-index: 98 !important;
  position: relative;
  float:right;
}
a:link { text-decoration: none;z-index: 99 !important; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }

.nav-left {
  height: 100%;
  width: 100px;
  padding-left: 2vw;
  z-index: 50;
}
.logo {
  background-image: url('../images/Birthday.png');

  background-position: 0% 50%;
  background-size: 100%;
  width: 100%;
  height: 100%;

}

.nav-right {
  padding-top: 30px;
  padding-left: 45em;
  position:absolute;  
  height:100%;
  width:80%;
  float:right;

}

.right-nav {
  padding-top: 30px;
  padding-left: 0em;
  padding-right: 13em;
  z-index: 10;
  height:100%;
  width:80%;
  float:right;

}
/* 
@media screen and (max-width: 1000px) {
  .right-nav {
    padding-right: 1em;
  }
  .nav-left {
    padding-left: 15em;
    height: 30%;
  }
  .logo {
    padding-top: 3rem;
    width: 6.5rem;
    height: 6.5rem;
  }
}


@media screen and (max-width: 700px) {
  .right-nav {
    padding-right: 0em;
    display:none;
  }
  .nav-link-contact {
    margin: 0px;
  }
  .nav-left {
    padding-left: 12em;
    height: 30%;
  }
  .logo {
    padding-top: 3rem;
    width: 6.5rem;
    height: 6.5rem;
  }
} */
