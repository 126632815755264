
div {
    text-align: center;
}

.verse_text_light {
    font-weight: normal;
    font-style: italic;
    margin: 35px 35px 35px 35px;
}
  