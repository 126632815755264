.latest-container{
    padding: 50px 100px 100px 100px;
    width: 70%;
    margin: 0 auto;
    justify-content: center;

    
}



.display-card {

    background-color: rgba(255, 255, 255, 0.8);
  
    padding: 0px;
    width: 85%;
    transition: transform .3s;
    display: inline-block;
    height: 450px;
    box-shadow: 0px 0px 10px #b3b3b3;
}

.display-card:hover {
    transform: scale(1.01);
}

.display-image {
    background-repeat: no-repeat;
    object-fit: contain;
    background-position: 0% 50%;
    background-size: cover;
    width: 100%;
    height: 38%;
    max-height:100%; max-width:100%;
    filter: grayscale(50%) saturate(110%);
}

.display-passage {
    padding: 0px 10px 0px 10px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: #ececec;
    outline-width: 2px;
    margin: 20px 0px 0px 10px;
    font-size: small;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    float: left;   
}

.display-title {
    width: 100%;
    text-align: left;
    font-weight: bold;
    font-size: 23px;
    float: left;
    padding: 1px 0px 0px 10px;
}

.display-desc {
    /* background-color: rgb(152, 152, 152); */
    display:block;
    text-align: left;
    font-size: 1rem;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    height: 100px;
    font-family: "Poppins", sans-serif;
}

.display-date {
    /* background-color: rgb(152, 152, 152); */
    width: 90%;
    position: static;
    text-align: right;
    margin-top: 10px;
    font-style: italic;
    font-size: 0.7rem;
    padding: 15px 15px 5px 15px;
    font-family: "Poppins", sans-serif;
}

::selection {
    background: #e0d4b8; /* WebKit/Blink Browsers */
  }
  ::-moz-selection {
    background: #e0d4b8; /* Gecko Browsers */
  }

  @media screen and (max-width: 930px) {
    .display-card {
        height: fit-content;
        width: 100%;
    }
    .display-image {

        height: 20vh;

    }
    
    .flex-grid-thirds {
        
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: auto;
        grid-gap: 25px;
        width: 100%;
        justify-content: center;
        margin: 0 auto;
        position: relative;
        grid-template-columns: repeat(auto-fit, minmax(max(100%, 20px), 00.8fr));;
      }
    .flex-grid-thirds .col {
        padding: 10px;
        font-size: 30px;
        text-align: center;
        width: 65%;


        justify-content: center;
        margin: 0 auto;

    }
    .latest-container {
        width: 100%;
        padding: 0px 0px 0px 0px;
    }
    .display-desc {
        /* height: 0px;
        font-size: 0px; */
        height: fit-content;
        margin-bottom: 50px;
    }
    .display-title {
        white-space:inherit;
    }

}

@media screen and (min-width: 500px) and (max-width: 550px)  {
    .flex-grid-thirds {
        
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: auto;
        grid-gap: 12px;
        width: 65%;
        justify-content: center;
        margin: 0 auto;
        grid-template-columns: repeat(auto-fit, minmax(max(50%, 20px), 00.8fr));;
      }
    .flex-grid-thirds .col {
        padding: 10px;
        font-size: 30px;
        text-align: center;
        width: 100%;
        display: inline-block;
        margin: 0 auto;
    }
}


@media screen and (min-width: 550px) and (max-width: 700px)  {
    .flex-grid-thirds {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: auto;
        grid-gap: 12px;
        width: 100%;
        justify-content: center;
        margin: 0 auto;
        grid-template-columns: repeat(auto-fit, minmax(max(33%, 20px), 00.8fr));;
      }
    .flex-grid-thirds .col {
        padding: 10px;
        font-size: 30px;
        text-align: center;
        width: 100%;
        display: inline-block;
        margin: 0 auto;
    }
    .display-card {
        height: 300px;
        width: 75%;
    }
    .display-card {
        height: fit-content;
        width: 75%;
    }
    
}

@media screen and (min-width: 700px) and (max-width: 1250px)  {
    .flex-grid-thirds {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: auto;
        grid-gap: 12px;
        width: 80%;
        justify-content: center;
        margin: 0 auto;
        grid-template-columns: repeat(auto-fit, minmax(max(33%, 20px), 00.8fr));;
      }
    .flex-grid-thirds .col {
        padding: 10px;
        font-size: 30px;
        text-align: center;
        width: 100%;
        display: inline-block;
        margin: 0 auto;
    }
    .display-title {
        /* background-color: rgb(89, 89, 89); */
        font-size: 20px;
    }
    .display-card {
        height: 400px;
        width: 90%;
    }
    .display-desc {
        height: 100px;
        font-size: 1rem;

    }
}

@media screen and (min-width: 1250px) {
    .flex-grid-thirds {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: auto;
        grid-gap: 12px;
        grid-template-columns: repeat(auto-fit, minmax(max(27%, 140px), 1fr));;
      }
    .flex-grid-thirds .col {
        padding: 10px 10px 80px 10px;
        font-size: 30px;
        text-align: center;
    }
    .display-card {

        width: 100%;
    }
}


@media screen and (min-width: 1450px) {
    .flex-grid-thirds {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: auto;
        grid-gap: 12px;
        grid-template-columns: repeat(auto-fit, minmax(max(27%, 140px), 1fr));;
      }
    .flex-grid-thirds .col {
        padding: 10px 10px 80px 10px;
        font-size: 30px;
        text-align: center;
    }
    .display-card {
        width: 82%;
    }
}

