body {
    font-family: "Raleway", sans-serif;
}


.main-container{
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow-x: hidden;
    background-color: white;
    top: 0;
    left: 0;

}

.l-title {
    margin: auto;
    width: 70%;
    height: 4rem;
    text-align: center;
    color: #fff;
    font-size: 3.5rem;
    margin-top: 12rem;
    margin-bottom: 0px;
    font-weight: 1000;
    word-wrap: break-word;
    padding: 30px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.footer {

    left: 0;
    bottom: 0;
    width: 100%;
    height: 5rem;

    background-color: #d1d0cf36;
    /* background-color: rgb(188, 191, 105); */
    color: white;
    text-align: center;
  }

.l-passage {
    margin: auto;
    width: 15%;
    text-align: center;
    font-size: 2rem;
    color: #fff;
    /* background-color: #6084bd; */
    padding: 5px;
    margin-bottom: 5px;
}

.l-image {
    margin: auto;
    width: 100%;
    height: 21rem;
    /* background-color: #6084bd; */
    background-image: url('../../images/wheat_field.jpg');

    
    background-size: 100% ;
    background-repeat: no-repeat;
    background-position: 0% 50%;background-position: 0% 50%;
    overflow: hidden;
    padding: 40px;
    margin-bottom: 10px;
}

.l-content {
    margin: auto;
    width: 50%;
    text-align: left;
    /* background-color: #6084bd; */
    padding: 40px;
    margin-bottom: 15px;
}

.l-date {
    /* float: right; */
    color: #fff;
    padding: 5px;
    margin-bottom: 5px;
    
}

@media screen and (max-width: 900px) {
    .l-image {
        height: 8rem;
    }
}

@media screen and (min-width: 900px) {
    .l-image {
        height: 12rem;
    }
}

@media screen and (min-width: 1400px) {
    .l-image {
        height: 15rem;
        background-position: 0% 35%;
    }
}
