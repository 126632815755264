hr {

}


.top_hr {
    /* left: -5em; */
}

.bot_hr {
    height: 0.5px;
    background-color: rgba(122,98,10);
    position: relative;
    width:7%;
}

div {
    text-align: center;

}

.verse_text {
    font-weight: 550;
    font-size: large;
    font-style: italic;
    max-width: 70%;
    text-align: center;
    margin: 0 auto;
}
  