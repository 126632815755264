body {
    font-family: "Raleway", sans-serif;

}


.content-container{

    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 8rem;
    position: absolute;

    margin: auto;
    overflow-x: hidden;
    background-color: #fffcf9;

    /* background-color: #85aff2; */
}

.d-references > div {
    margin: auto;
    width: 50%;
    text-align: left;
    font-style: italic;
    /* background-color: #6084bd; */
    font-size: 13px;
    display: flex;
}

.d-references {
    display: grid;

}

.d-passage {
    padding:1px 5px 0px 0px;
    padding-left: 11vw;

    width:70%;
    text-align: left;
    border-radius: 5%;
}

.d-passage-box {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: #ececec;
    outline-width: 2px;
    margin: 5px 5px 0px 0px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: clamp(15px, 20px, 1.2vw);
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    float: left;   
    cursor: pointer;
}


.d-title {
    padding-left: 10vw;
    width: 70%;
    max-height: calc(max(45px, 6.5vw));
       
    word-wrap: break-word;
    vertical-align: top;
    text-align: left;
    color: #000;
    font-size: calc(max(35px, 5vw));
}

.d-short-desc {
    /* float: right; */
    color: rgba(85, 85, 85, 0.469);
    padding-left: 11vw;
    width: 70%;
    text-align: left;
    font-size: clamp(15px, 30px, 1.3vw);

    padding-top: 0.5vw;
    padding-bottom: 15px;
    /* padding-left: 35px; */
}



.d-image {
    margin: auto;
    width: 100%;
    height: 23rem;
    /* background-color: #6084bd; */
    /* background-image: url('../../images/clouds.jpg'); */
    background-size: 100% ;
    background-repeat: no-repeat;
    background-position: 0% 95%;
    overflow: hidden;
    padding: 40px;
    margin-bottom: 10px;
}

.d-image-reference {
    text-align:right;
    color: grey;
    float: right;
    text-decoration: underline;
    font-size: 80%;
    cursor: pointer;
}

.d-content {
    margin: auto;
    width: 50%;
    text-align: left;
    /* background-color: #6084bd; */
    font-size: 20px;
    padding: 40px;
    margin-bottom: 15px;
}


.d-date {
    /* float: right; */
    color: #000;
    margin: auto;
    width: 70%;
    text-align: left;
    
    padding-top:15px;
    padding-left: 35px;
    margin-bottom: 5px;
}

@media screen and (max-width: 800px) {
    .d-title {
        /* font-size: 2.5rem; */
    }
    /* .d-short-desc {
        font-size: 15px; 
    } */
    .d-content{
        width: 70%;
    }
  }



@media screen and (max-width: 550px) {
    .d-title {
        /* font-size: 2.3em; */
    }
    .d-image {
        height: 10rem;
    }
    /* .d-short-desc {

        padding-top:5px;
        font-size: 23px; 
    } */
    .d-content{
        width: 80%;
    }
  }

 